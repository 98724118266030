<template>
  <YForm v-model="formvalues" inline>
    <div class="question-list">
      <div class="question-item" v-for="(question, index) in formvalues.selectedList" :key="question.repoId">
        <h5 class="name">题库名称：{{ question.title }}</h5>
        <div class="table">
          <div class="tr">
            <div class="th">题型</div>
            <div class="th">设置题数</div>
            <div class="th">每题分数</div>
          </div>
          <div class="tr">
            <div class="td">单选题（{{ formvalues.selectedList[index].totalRadio }}）</div>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].radioCount`"
              :rules="['digital', minValidate, setMax(formvalues.selectedList[index].totalRadio)]"
            ></YField>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].radioScore`"
              :rules="['digital', minValidate]"
            ></YField>
          </div>
          <div class="tr">
            <div class="td">多选题（{{ formvalues.selectedList[index].totalMulti }}）</div>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].multiCount`"
              :rules="['digital', minValidate, setMax(formvalues.selectedList[index].totalMulti)]"
            ></YField>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].multiScore`"
              :rules="['digital', minValidate]"
            ></YField>
          </div>
          <div class="tr">
            <div class="td">判断题（{{ formvalues.selectedList[index].totalJudge }}）</div>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].judgeCount`"
              :rules="['digital', minValidate, setMax(formvalues.selectedList[index].totalJudge)]"
            ></YField>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].judgeScore`"
              :rules="['digital', minValidate]"
            ></YField>
          </div>
          <div class="tr">
            <div class="td">填空题（{{ formvalues.selectedList[index].totalInput }}）</div>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].inputCount`"
              :rules="['digital', minValidate, setMax(formvalues.selectedList[index].totalInput)]"
            ></YField>
            <YField
              @blur="setVal"
              class="td"
              :name="`selectedList[${index}].inputScore`"
              :rules="['digital', minValidate]"
            ></YField>
          </div>
        </div>
      </div>
    </div>
  </YForm>
</template>

<script>
export default {
  props: ['list'],
  data() {
    return {
      formvalues: {},
      minValidate: {
        validator(rules, value, callback) {
          if (value < 0) {
            return callback(new Error('最小值不得小于0'))
          }
          callback()
        },
      },
    }
  },
  computed: {
    name() {
      return this.data
    },
  },
  watch: {
    list: {
      deep: true,
      handler() {
        this.formvalues.selectedList = this.list.map(item => {
          return {
            ...item,
            judgeCount: item.judgeCount || 0,
            inputCount: item.inputCount || 0,
            multiCount: item.multiCount || 0,
            radioCount: item.radioCount || 0,
          }
        })
        this.setVal()
      },
      immediate: true,
    },
  },
  methods: {
    setVal() {
      this.$emit('input', this.formvalues.selectedList)
    },
    setMax(max) {
      return {
        validator(rules, value, callback) {
          if (value > max) {
            return callback(new Error('不得高于总题数'))
          }
          callback()
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.question-list {
  padding: 15px;
  border-radius: 14px;
  background: #f4f9fd;
}
.question-item {
  margin-bottom: 8px;
}
.th,
.td {
  color: #91929e;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  line-height: 20px;
}
.tr {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  > div {
    flex: 1;
  }
}
</style>
